/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $('.flexslider').flexslider({
         animation: "slide",
         slideshow:false

         
       });


// begin affix
$(window).ready(function() {
  var viewport = $(window).width();

  if ( viewport <= 991 ) {
    console.log( "Mobile,", viewport ) ;}
    else {
      console.log( "Desktop,", viewport ) 
      var toggleAffix = function(affixElement, scrollElement, wrapper) {

        var height = affixElement.outerHeight(),
        top = wrapper.offset().top;

        if (scrollElement.scrollTop() >= top){
          wrapper.height(height);
          affixElement.addClass("affix");
        }
        else {
          affixElement.removeClass("affix");
          wrapper.height('auto');
        }

      };


      $('[data-toggle="affix"]').each(function() {
        var ele = $(this),
        wrapper = $('<div></div>');

        ele.before(wrapper);
        $(window).on('scroll resize', function() {
          toggleAffix(ele, $(this), wrapper);
        });

    // init
    toggleAffix(ele, $(window), wrapper);
  });
    };
  });





// end affix

$('body').scrollspy({ target: '#navbar-example' });

},
finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_expedition' : {
     init: function() {



      $(".video").click(function () {
        var theModal = $(this).data("target"),
        videoSRC = $(this).attr("data-video"),
        videoSRCauto = videoSRC + "?modestbranding=1&rel=0&controls=0&showinfo=0&html5=1&autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal).on('click', function(){
          $(theModal + ' iframe').attr('src', videoSRC);
        });
      });





    }
  }
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $('#myModal').on('shown.bs.modal', function (e) {
    $('input.search-field', this).trigger('focus');
  });

})(jQuery); // Fully reference jQuery after this point.
